export const skillsFamiliarData = [
  {
    skillName: "Sass",
    img: "https://i.ibb.co/FKcPC9Z/sass.png",
    alt: "sass",
  },
  {
    skillName: "Three.js",
    img: "https://i.ibb.co/bWGm19R/e4f86d2200d2d35c30f7b1494e96b9595ebc2751.png",
    alt: "three.js",
  },
  {
    skillName: "Figma",
    img: "https://i.ibb.co/wJXZFhD/figma.png",
    alt: "figma",
  },
  {
    skillName: "Git",
    img: "https://i.ibb.co/n6ygMbb/Git-Logo-2-Color.png",
    alt: "git",
  },
  {
    skillName: "Next.js",
    img: "https://i.ibb.co/q1PT6y0/1684410508-image-7.webp",
    alt: "nextjs",
  },
  {
    skillName: "Material UI",
    img: "https://i.ibb.co/0nSddwG/pngwing-com.png",
    alt: "mui",
  },

  {
    skillName: "Ant Design",
    img: "https://i.ibb.co/Bw83D7y/clipart2918729.png",
    alt: "ant-design",
  },
  {
    skillName: "Framer Motion",
    img: "https://i.ibb.co/0QBf0zq/cover.png",
    alt: "framer-motion",
  },
  {
    skillName: "Sanity CMS",
    img: "https://i.ibb.co/RBLBY4y/sanity.webp",
    alt: "sanity.io",
  },
  {
    skillName: "Cloudinary",
    img: "https://i.ibb.co/z2KBypq/stacked-logo-blue.png",
    alt: "cloudinary",
  },
];
